<template>
  <div class="wrapper error register"></div>
</template>

<script>
import { postReg, postRegNone } from '../../functions/api/content';

export default {
  created() {
    window.localStorage.setItem('locale', this.$route.params.lang);
    this.$root.$i18n.locale = window.localStorage.getItem('locale');

    this.$store.commit('setSkyboxTitle', '');
    this.$store.commit('setSkyboxText', '');
    this.$store.commit('setSkyboxLink', '');
    this.$store.commit('setSkyboxLinkText', '');
    this.$store.commit('hideNavigation');

    this.signupUser();

    setTimeout(() => {
      this.$store.commit('setApploading', true);
    }, 600);
  },
  methods: {
    next() {
      console.info('sending postmessage with these parameters', {
        orderstride: {
          registered: true,
        },
      });

      window.parent.postMessage(
        {
          orderstride: {
            registered: true,
          },
        },
        '*'
      );
    },
    signupUser() {
      let type = 'none';
      let mvmId = this.$route.params.mvmId;
      let email = this.$route.params.email;
      let name = this.$route.params.name;

      if (typeof this.$route.params.premiumType !== undefined) {
        type = this.$route.params.premiumType;
      }

      if (type == 'none') {
        postRegNone(mvmId, email, name, this.$store.getters['getSavedLocale'])
          .then((result) => {
            this.$store.commit('setMvmId', result.mvmId);
            this.next();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        postReg(mvmId, type, email, name, this.$store.getters['getSavedLocale'])
          .then((result) => {
            this.$store.commit('setMvmId', result.mvmId);
            this.next();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
.yellowBtn {
}
</style>
